<template>
  <TLoading v-if="$fetchState.pending" />
  <t-page v-else>
    <template #header>
      <h3>{{ $t('pages.organization.actions.create') }}</h3>
    </template>

    <t-form-card>
      <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(save)">
          <t-grid>
            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="name"
                  :name="$t('fields.name')"
                  rules="required|min:3|max:255"
                >
                  <t-input
                    v-model="form.name"
                    native-type="text"
                    :placeholder="$t('fields.organization_name_placeholder')"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <Address v-model="form.address" />

            <t-grid-row>
              <div
                style="
                  flex-direction: row-reverse;
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <t-button-loading :disabled="invalid" :loading="loading" native-type="submit">
                  {{ $t('global.actions.save') }}
                </t-button-loading>
              </div>
            </t-grid-row>
          </t-grid>
        </t-form>
      </validation-observer>
    </t-form-card>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Organization from '~/models/Organization';
import TLoading from '~/components/TLoading';
import TFormCard from '~/components/TFormCard';
import TValidationWrapper from '~/components/TValidationWrapper';
import Address from '~/components/Fields/Address';

export default Vue.extend({
  components: {
    TLoading,
    TFormCard,
    TValidationWrapper,
    // eslint-disable-next-line vue/no-reserved-component-names
    Address,
  },

  data() {
    return {
      form: null,
      loading: false,
    };
  },

  fetch() {
    this.form = this.getForm();
  },

  methods: {
    getForm(organization = null) {
      return {
        name: organization?.name,
        tenant_id: this.$auth.me.tenant_id,
        address: {
          line_1: organization?.address?.line_1,
          line_2: organization?.address?.line_2,
          postcode: organization?.address?.postcode,
          city: organization?.address?.city,
          country: organization?.address?.country,
        },
      };
    },

    getPayload() {
      return {
        ...this.form,
      };
    },

    async save() {
      return await this.create(this.getPayload());
    },

    async create(payload) {
      this.loading = true;
      try {
        await new Organization({
          ...payload,
        }).save();

        await this.$notify.success(this.$t('notifications.organization.created'));
        await this.$router.push({ name: this.$RouteEnum.ORGANIZATIONS.INDEX });
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
