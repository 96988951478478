<template>
  <organizations-create />
</template>

<script>
import Vue from 'vue';
import OrganizationsCreate from '~/pages/shared/organizations/partials/Create.vue';

export default Vue.extend({

  components: {
    OrganizationsCreate,
  },

  middleware: 'tenantmember',
});
</script>
